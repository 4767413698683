import { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import Header from '../../components/Home/Header'
import * as S from './style'

import API, { endpointers } from '../../services/api'
import * as LOCALSTORAGE from '../../services/localStorage'
import SOCKET from '../../services/socket'
import * as UTILS from '../../utils'

function DetalhePedido({ redux_lang }) {
	const { query = {} } = useLocation()
	const { go } = useHistory()
	const { addToast } = useToasts()

	const { data: data_query } = query

	const [reording, setReording] = useState(false)
	const [reorderStep, setReorderStep] = useState(2)
	const [data, setData] = useState({ ...data_query })

	const isMan = useMemo(() => {
		return data._schema === 'manutencao' || data._schema === 'tecnologia'
	}, [])

	// const total = data_query && data.servicos && data.servicos.map((servico, index) => {
	//   const { quantidade } = data.itens[index]

	//   return (servico._valor * quantidade)
	// }).filter(v => !!v).reduce((acc, total) => (acc + total), 0)

	const total =
		data_query &&
		data.itens &&
		data.itens
			.map(({ valor, quantidade }, index) => {
				return valor * quantidade
			})
			.filter((v) => !!v)
			.reduce((acc, total) => acc + total, 0)

	const _handleReoder = useCallback(async () => {
		if (reording || reorderStep) return

		setReording(true)

		try {
			let services = []

			if (data.itens && data.itens.length) {
				const { data: dataFromApi } = await API.post(
					endpointers.get.latest_services_prices,
					{ _ids: data.itens.map(({ servico_id }) => servico_id) },
					{ headers: { Authorization: LOCALSTORAGE.token.get() } }
				)

				if (dataFromApi.ok) {
					services = dataFromApi.data
				}
			}

			const { data: dataFromApi } = await API.post(
				endpointers.post.order,
				{
					...data,
					data: UTILS.dateFormated(),
					itens: data.itens
						? data.itens.map((item, index) => {
								let servico = services.find(
										({ _id }) => _id === item.servico_id
									),
									valor = 0

								if (servico) {
									valor = servico._valor
								} else {
									const { _valor } = data.servicos[index]

									valor = _valor
								}

								return {
									...item,
									valor,
								}
						  })
						: [],
					servicos: undefined,
					categoria: undefined,
				},
				{ headers: { Authorization: LOCALSTORAGE.token.get() } }
			)

			if (!dataFromApi.ok) {
				throw new Error(dataFromApi.message)
			}

			addToast(
				redux_lang === 'pt'
					? 'Pedido refeito com sucesso'
					: 'Order redone successfully',
				{
					appearance: 'success',
					autoDismiss: true,
				}
			)
		} catch (e) {
			console.error(e)
		} finally {
			setReorderStep(2)
			setReording(false)
		}
	}, [reorderStep, reording, redux_lang])

	useEffect(() => {
		if (!reorderStep) _handleReoder()
	}, [reorderStep])

	useEffect(() => {
		SOCKET.on(
			'atualizar_status_do_pedido',
			({ _id, status, entregue } = {}) => {
				if (_id === data._id) {
					setData((oldState) => ({ ...oldState, status, entregue }))
				}
			}
		)
	}, [])

	if (!data._schema) {
		go(-1)

		return null
	}

	return (
		<S.Container>
			<Header />
			<S.Header>
				<h1>
					<Link to='/meuspedidos'>
						{redux_lang === 'pt' ? 'Meus pedidos' : 'My orders'} /{' '}
					</Link>
					<span>
						{redux_lang === 'pt' ? 'Pedido' : 'Order'} #{data.pedido_id}
					</span>
				</h1>
				<div className='infos'>
					<h4>{data.data[redux_lang]}</h4>
					<h4>
						Status:{' '}
						<span
							className={UTILS.getStatus({ status: data.status }).toLowerCase()}
						>
							{UTILS.getStatus({
								status: data.status,
								lang: redux_lang,
								manutencao: data._schema == 'manutencao',
							})}
						</span>
						{data.status == 1 && data._schema != 'manutencao' && data.entregue
							? ` ${data.entregue.hora}`
							: ''}
					</h4>
					{data.status == 1 && isMan && data.entregue ? (
						<h4>
							Resolvido:{' '}
							{`${data.entregue.semana}, ${data.entregue.dia_e_mes} ${data.entregue.hora}`}
						</h4>
					) : null}
				</div>
			</S.Header>
			<S.Content>
				<span>{redux_lang === 'pt' ? 'Meu(s) Pedido(s)' : 'My order(s)'}:</span>
				<div className='pedidos'>
					{isMan ? (
						<div className='card manutencao'>
							<div>
								<span>{redux_lang === 'pt' ? 'Tipo' : 'Type'}:</span>
								<h4>{data.categoria.texto[redux_lang]}</h4>
							</div>
							{data.titulo.length ? (
								<div>
									<span>{redux_lang === 'pt' ? 'Titulo' : 'Title'}:</span>
									<h4>{data.titulo}</h4>
								</div>
							) : null}
							<div>
								<span>
									{redux_lang === 'pt' ? 'Descrição' : 'Description'}:{' '}
								</span>
								<h4>{data.descricao}</h4>
							</div>
						</div>
					) : (
						<>
							{data.servicos.map(({ _id, titulo, medida }, index) => {
								const { quantidade, observacao = '', valor } = data.itens[index]

								return (
									<div key={_id} className='card'>
										<h4 className='titulo'>
											{quantidade < 10 ? `0${quantidade}` : quantidade}{' '}
											{titulo[redux_lang]}{' '}
											{medida[redux_lang] && medida[redux_lang].length
												? `- ${medida[redux_lang]}`
												: ''}
										</h4>
										{valor ? (
											<span className='valor'>{UTILS.getPrice(valor)}</span>
										) : null}
										{observacao.length ? (
											<div className='observacao'>
												<span>
													{redux_lang === 'pt' ? 'Observação' : 'Remark'}
												</span>
												<p>{observacao}</p>
											</div>
										) : null}
										{valor ? (
											<h4>Subtotal: {UTILS.getPrice(valor * quantidade)}</h4>
										) : null}
									</div>
								)
							})}
						</>
					)}
				</div>
				<div className='detalhes'>
					{!isMan ? (
						<div className='local'>
							<span>{redux_lang === 'pt' ? 'Entregar em' : 'Deliver in'}:</span>
							<h4> {UTILS.capitalize(data.categoria.texto[redux_lang])} </h4>
						</div>
					) : null}
					{+total ? (
						<h4>
							Total: <span>{UTILS.getPrice(total)}</span>
						</h4>
					) : null}
				</div>
				<div className='acoes'>
					{!isMan ? (
						<button
							className={reorderStep === 2 ? 'azul' : 'verde'}
							disabled={reording || !reorderStep}
							onClick={() => setReorderStep((oldState) => oldState - 1)}
						>
							{reording
								? redux_lang === 'pt'
									? 'Aguarde...'
									: 'Wait...'
								: reorderStep === 2
								? redux_lang === 'pt'
									? 'Refazer pedido'
									: 'Reorder'
								: redux_lang === 'pt'
								? 'Confirmar'
								: 'Confirm'}
						</button>
					) : null}
					<button
						className='cinza'
						onClick={() =>
							reorderStep === 2
								? go(-1)
								: setReorderStep((oldState) => oldState + 1)
						}
						disabled={reording || !reorderStep}
					>
						{reorderStep === 1
							? redux_lang === 'pt'
								? 'Cancelar'
								: 'Cancel'
							: redux_lang === 'pt'
							? 'Voltar'
							: 'Back'}
					</button>
				</div>
			</S.Content>
		</S.Container>
	)
}

const mapStateToProps = (state) => ({
	redux_lang: state.lang,
})

export default connect(mapStateToProps)(DetalhePedido)
