import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import * as S from './styles'
import * as SS from '../styles'
import * as SC from '../Carrinho/style'
import * as SU from '../../../../utils/styles'

import CART from '../../../../services/redux/reducer/actions/cart'
import CONFIG from '../../../../services/redux/reducer/actions/config'

import API, { endpointers } from '../../../../services/api'
import * as UTILS from '../../../../utils'
import * as LOCALSTORAGE from '../../../../services/localStorage'

import Header from '../../Header'
import Item from '../Item';
import ItemCart from '../Carrinho/Item';

//imagem
import lupa from '../../../../assets/images/MenuItens/lupa.svg'
import seta from '../../../../assets/images/Modal/seta-baixo.png'
import cart from '../../../../assets/images/MenuItens/cart.svg'
import sucess from '../../../../assets/images/FaleConosco/Sucesso/sucesso.svg'

function MenuItens({ 
  redux_lang, redux_config, redux_setCart, redux_setConfig, redux_roomCart = [], redux_user 
}) {
  const [requesting, setRequesting] = useState(true)
  const [wish, setWish] = useState({ ...UTILS.submit_state })
  const [category, setCategory] = useState('')
  const [location, setLocation] = useState('')
  const [term, setTerm] = useState('')
  const [itemExpanded, setItemExpanded] = useState()
  const [cartOpenned, setCartOpenned] = useState(false)
  const [detailsOpenned, setDetailsOpenned] = useState(false)
  const [ready, setReady] = useState(false)
  const [list, setList] = useState([])
  const [categories, setCategories] = useState([])
  const [locales, setLocales] = useState([])

  const date = useMemo(() => new Date(), [])

  const { addToast } = useToasts()
  const { push } = useHistory()

  const searchInput = useRef()


  const getCategories = useCallback(async () => {
    if (!redux_user) return

    const { data: dataFromApi } = await API(endpointers.get.category({ _schema: 'quarto', locales: true }))

    if (dataFromApi.ok) {
      setCategories(dataFromApi.data.cats)
      setLocales(dataFromApi.data.locales)
    }

  }, [redux_user])

  const request = useCallback(async ({ categoria_id, name, force }) => {

    if (!redux_user) return

    if (requesting && !force) return

    setRequesting(true)

    try {
      const { data: dataFromApi } = await API.get(endpointers.get.room({ nome: name, categoria_id, lang: redux_lang }), { headers: { Authorization: LOCALSTORAGE.token.get() } })
  
      if (!dataFromApi.ok) {
        return addToast(dataFromApi.message, {
          appearance: 'error',
          autoDismiss: true
        })
      }

      setList(dataFromApi.data)
    } catch(e) {
      console.error(e)
    } finally {
      setRequesting(false)
      !ready && setReady(true)
    }

  }, [requesting, ready, redux_lang, redux_user])

  useEffect(() => {
    request({ categoria_id: category, name: term, force: true })
  }, [])

  useEffect(() => {
    if (ready) {
      request({ categoria_id: category, name: term })
    }
  }, [category, term])

  useEffect(() => {
    if (!cartOpenned) {
      setLocation('')
      setTimeout(() => {
        setWish({ ...UTILS.submit_state })
      }, 500)
    }
  }, [cartOpenned])

  useEffect(() => {
    if (itemExpanded) {
      setDetailsOpenned(true)
    }
  }, [itemExpanded])

  useEffect(() => {
    if (!detailsOpenned) {
      setTimeout(() => {
        setItemExpanded()
      }, 500)
    }
  }, [detailsOpenned])

  const _handleDoSearch = useCallback((e) => {
    e.preventDefault()
    
    if (searchInput.current) {
      if (searchInput.current.value === term) {
        request({ categoria_id: category, name: term })
      } else {
        setTerm(searchInput.current.value)
      }
    }
  }, [searchInput, term, category])

  const _handleAdd = useCallback(() => {
    if (itemExpanded) {

      redux_setCart([
        { ...itemExpanded },
        ...redux_roomCart
      ])

      setDetailsOpenned(false)
      setCartOpenned(true)
    }
  }, [itemExpanded, redux_roomCart])

  const _handleDoWish = useCallback(async () => {
    if (wish.req || !location.length) {
      return addToast(`${redux_lang === 'pt' ? 'Selecione um local de entrega' : 'Select a delivery location'}`, {
        appearance: 'error',
        autoDismiss: true
      })
    }

    setWish(oldState => ({ ...oldState, req: true }))
 
    let status = true
    
    try {
      const data = {
        cliente: {
          id: redux_user?.IdHospede,
          sobrenome: redux_user?.Sobrenome,
          apartamento: redux_user?.CodUH
        },
        _schema: 'quarto',
        itens: redux_roomCart.map(({ _id, observacao, quantidade, _valor }) => ({
          servico_id: _id,
          observacao, quantidade, valor: _valor
        })),
        valor: redux_roomCart.reduce((acc, { _valor, quantidade }) => {
            if (_valor) {
              acc = acc + (_valor * quantidade)
            }

            return acc
          }, 0).toFixed(2), 
        categoria_id: location
      }
      
      const { data: dataFromApi } = await API.post(endpointers.post.order, data, { headers: { Authorization: LOCALSTORAGE.token.get() } })
      
      if (!dataFromApi.ok) {
        throw new Error(dataFromApi.message)
      }

    } catch(e) {
      console.error(e)
      addToast(redux_lang === 'pt' ? 'Ocorreu um erro' : 'An error has occurred', {
        appearance: 'error',
        autoDismiss: true
      })
      status = false
    } finally {
      setTimeout(() => {
        setWish({ req: false, done: true, status })
        if (status) {
          redux_setCart([])
        }
      }, 1000)
    }
    
  }, [wish, location, redux_roomCart, redux_user, redux_lang])

  const _handleQtdOnCart = useCallback(({ index, increment, quantidade }) => {
    redux_setCart(
      redux_roomCart.map((_, i) => ({
        ..._,
        quantidade: i === index ? (increment ? quantidade + 1 : quantidade === 1 ? 1 : quantidade - 1) : _.quantidade
      }))
    )
  }, [redux_roomCart])

  const _handleTrash = useCallback((index) => {
    const list = redux_roomCart.map((_, i) => i === index ? false : _).filter(v => v)
    redux_setCart(list)
  }, [redux_roomCart])

  
  const getConfig = useCallback(async () => {
    if (!redux_user) return

    if (!redux_config) {
      try {
        const { data: dataFromApi } = await API(endpointers.get.config, { headers: { Authorization: LOCALSTORAGE.token.get() } })
    
        if (dataFromApi.ok) {
          redux_setConfig(dataFromApi.data)
        }
      } catch(e) {
        alert(redux_lang === 'pt' ? 'Ocorreu um erro ao buscar configurações' : 'An error occurred while fetching settings')
        window.location.reload()
      }
    }

  }, [redux_user, redux_config, redux_lang])

  useEffect(() => {
    getCategories()
    getConfig()
  }, [])

  if (!redux_user) {
    push('/logar', {
      redirect: 'servicos-do-restaurante'
    })
    return null
  }

  return(
    <SS.Container>
      <Header />
      <SS.TopoContainer>
        <SS.Topo>
          <S.Topo>
            <h1>{ redux_lang === 'pt' ? 'Restaurante' : 'Restaurant' } { term.length ? `- ${ term }` : '' }</h1>
            {
              redux_config ? (
                <>
                  <h2>{ UTILS.getTimeString(redux_lang, { start: redux_config.funcionamento.inicio, end: redux_config.funcionamento.fim }) }</h2>
                </>
              ) : null
            }
          </S.Topo>
          <SC.CarrinhoButton onClick={_ => {
            setItemExpanded(null)
            setCartOpenned(true)
          }}>
            <img src={cart} alt="" />
          </SC.CarrinhoButton>
        </SS.Topo>
        <SS.Filters>
          <form onSubmit={ _handleDoSearch }>
            <input 
              ref={ searchInput }
              type="text"
              placeholder={ redux_lang === 'pt' ? 'Buscar' : 'Search' }
            />
            <button type='submit'>
              <img src={lupa} alt=""/>
            </button>
          </form>
          <div>
            <select name="categoria" id="categoria" value={ category } onChange={({ target: { value } }) =>  setCategory(value)}>
              <option value="">{ redux_lang === 'pt' ? 'Selecione uma categoria' : 'Select a category' }…</option>
              {
                categories
                .sort((a, b) => a.texto[redux_lang].localeCompare(b.texto[redux_lang]))
                .map(({ _id, texto }) => (
                  <option key={ _id } value={ _id }>{ texto[redux_lang] }</option>
                ))
              }
            </select>
          </div>
        </SS.Filters>
      </SS.TopoContainer>

      <SS.Cards>
        {
          requesting || !list.length ? (
            <SS.EmptyOrLoading>
              <span>
                {
                  requesting ? redux_lang === 'pt' ? 'Por favor, aguarde ...' : 'Please, wait ...'
                    : redux_lang === 'pt' ? 'Nenhum item' : 'No items'
                }
              </span>
            </SS.EmptyOrLoading>
          ) : list
          .sort((a, b) => a.titulo[redux_lang].localeCompare(b.titulo[redux_lang]))
          .map(item => {
            const { startTime, endTime } = item.time || {}

            const event = () => {
              if (item.time) {
                const hour = date.getHours()

                if (hour < startTime || hour >= endTime) {
                  return alert(redux_lang === 'pt' ? 'Indisponível neste horário' : 'Not available at this time')
                }
              } 

              setItemExpanded({ ...item, quantidade: 1, observacao: '' })
            }
            
            return <Item 
              key={ item._id }
              titulo={ `${ item.titulo[redux_lang] } ${ item.medida[redux_lang].length ? `- ${ item.medida[redux_lang] }` : '' }` } 
              descricao={ item.categoria.texto[redux_lang] } 
              imagem={ item.url } 
              time={ item.time ? UTILS.getTimeString(redux_lang, { start: startTime, end: endTime }) : '' }
              preco={ item.valor ? UTILS.getPrice(item._valor) : undefined }
              _handleExpand={ event }
            />
              })
        }
      </SS.Cards>
      
      <SU.Modal className={detailsOpenned ? 'expandido' : 'escondido'}>
        <SS.Conteudo>
          <SS.Cabecalho>
            <button onClick={() => setDetailsOpenned(false)}>
              <img src={seta} alt="" />
            </button>
            <h1>{ redux_lang === 'pt' ? 'Detalhes do item' : 'Item Details'}</h1>
          </SS.Cabecalho>
          <SS.Imagem bg={ itemExpanded?.url } />
          <SS.Content>
            <SS.Infos>
              <h1>{ itemExpanded?.titulo[redux_lang] }</h1>
              <div className='descricao' dangerouslySetInnerHTML={{ __html: itemExpanded?.descricao[redux_lang] || '' }} />
              <span>{ UTILS.getPrice(itemExpanded?._valor) }</span>
              <form action="">
                <textarea placeholder={ redux_lang === 'pt' ? 'Adicionar observação ao pedido' : 'Add note to order' }
                  value={ itemExpanded?.observacao }
                  onChange={({ target: { value } }) => setItemExpanded(oldState => ({ ...oldState, observacao: value }))}
                />
              </form>
              <div className='acoes'>
                <div>
                  <button 
                    onClick={_ => setItemExpanded(oldState => ({ ...oldState, quantidade: oldState.quantidade === 1 ? 1 : oldState.quantidade - 1 }))}
                  > - </button>
                  <input value={ itemExpanded?.quantidade } disabled />
                  <button 
                    onClick={_ => setItemExpanded(oldState => ({ ...oldState, quantidade: oldState.quantidade + 1 }))}
                  > + </button>
                </div>
                <h1>Total: <span>{ UTILS.getPrice(itemExpanded?._valor * itemExpanded?.quantidade) }</span></h1>
              </div>
            </SS.Infos>
            <button onClick={ _handleAdd } className="azul">
              { redux_lang === 'pt' ? 'Adicionar ao carrinho' : 'Add to Cart' }
            </button>
          </SS.Content>

        </SS.Conteudo>
      </SU.Modal>

      <SU.Modal className={cartOpenned ? 'expandido' :  'escondido'}>
        <SC.Conteudo>
        <SC.Cabecalho>
          <button onClick={_ => setCartOpenned(false)}>
            <img src={seta} alt="" />
          </button>
          <h1>{ redux_lang === 'pt' ? 'Carrinho de pedidos' : 'Order cart' }</h1>
        </SC.Cabecalho>
        {
          wish.done && wish.status ? (
            <SU.ModalSuccessResponse>
                <img src={sucess} alt="" />
                <h1>{ redux_lang === 'pt' ? 'Pedido efetuado com sucesso' : 'Order successfully placed!' }!</h1>
                <button
                  onClick={() => {
                    push('/meuspedidos')
                  }}
                >{ redux_lang === 'pt' ? 'Meus pedidos' : 'My orders' }</button>
              </SU.ModalSuccessResponse>
          ) : (
            <>
              <SC.Entrega>
                  <span>{ redux_lang === 'pt' ? 'Entregar em' : 'Deliver in' }:</span>
                  <select value={ location } onChange={({ target: { value } }) =>  setLocation(value)}>
                    <option value=''>{ redux_lang === 'pt' ? 'Selecione o local' : 'Select location' }…</option>
                    {
                      locales
                      .sort((a, b) => a.texto[redux_lang].localeCompare(b.texto[redux_lang]))
                      .map(({ _id, texto }) => (
                        <option value={ _id }>{ texto[redux_lang] }</option>
                      ))
                    }
                  </select>
              </SC.Entrega>
              <span>{ redux_lang === 'pt' ? 'Meus pedidos' : 'My requests' }:</span>
              { 
                !redux_roomCart.length ? 
                (
                  <SC.Content>
                    <span>
                      { redux_lang === 'pt' ? 'Seu carrinho está vazio' : 'Your shopping cart is empty' }.
                    </span>
                  </SC.Content>
                ) :  (
                  <SC.Content>
                    <SC.Itens>
                      {
                        redux_roomCart.map((item, index) => {
                          const { titulo, quantidade, observacao, medida, _valor } = item

                          return (
                            <ItemCart 
                              titulo={ titulo[redux_lang] } medida={ medida[redux_lang] } 
                              quantidade={ quantidade } valor={ UTILS.getPrice(_valor) }
                              observacao={ observacao }
                              _handleQtd={ increment => _handleQtdOnCart({ index, increment, quantidade }) }
                              _handleTrash={ () => _handleTrash(index) }
                              pt={ redux_lang === 'pt' }
                            />
                          )
                        })
                      }
                      <h1>Total: <span>{ UTILS.getPrice(
                        redux_roomCart.reduce((acc, { _valor, quantidade }) => {
                          if (_valor) {
                            acc = acc + (_valor * quantidade)
                          }

                          return acc
                        }, 0)
                      ) }</span></h1>
                    </SC.Itens>
                    {
                      redux_config ?
                        (UTILS.isAvailableTime(redux_config.funcionamento.inicio, redux_config.funcionamento.fim)) ?
                          <button className="azul" onClick={ _handleDoWish } disabled={ wish.req }>{ wish.req ? `${ redux_lang === 'pt' ? 'Aguarde' : 'Wait'} ...` : redux_lang === 'pt' ? 'Fazer pedido' : 'Make a wish' }</button>:
                          <button disabled={ true }>{ redux_lang === 'pt' ? 'Restaurante indisponível neste horário' : 'Restaurant not available at this time' }</button> 
                      : null
                    }
                    <button disabled={ wish.req } onClick={_ => setCartOpenned(false)}>{ redux_lang === 'pt' ? 'Adicionar mais itens' : 'Add more items' }</button>
                  </SC.Content>
                  )
                }
            </>
          )
        }
        </SC.Conteudo>
      </SU.Modal>
    </SS.Container>
  )
}

const mapDispatchToProps = dispatch => ({
  redux_setCart(data) {
    dispatch(CART.call(data, 'room'))
  },
  redux_setConfig(data) {
    dispatch(CONFIG.call(data))
  }
})

const mapStateToProps = state => ({
  redux_lang: state.lang,
  redux_user: state.user,
  redux_roomCart: state.cart?.room || [],
  redux_config: state.config,
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuItens)
